.card-container {
  /* Layout */
  display: flex;
  width: 598px;
  margin-top: 3em;
  margin-left: 3em;
}

.tip-container {
  /* Typography */
  color: rgb(117, 117, 117);
  font-family: D2Coding ligature;

  /* Layout */
  margin-top: 6.5em;
  width: 100%;
  text-align: center;
}

.info-container {
  display: flex;
  flex-direction: column;
}

.label-name {
  /* Layout */
  display: flex;
  width: 287px;
  height: 38px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;

  /* Typography */
  color: #e6e6e6;
  font-family: Titillium Web;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 137.1%; /* 43.872px */
}

.label-nickname {
  /* Layout */
  display: flex;
  width: 287px;
  height: 27px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;

  /* Typography */
  color: rgb(202, 202, 202);
  font-family: Titillium Web;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 137.1%; /* 27.42px */
}

.icon-title {
  /* Layout */
  width: 69px;
  height: 69px;
  flex-shrink: 0;
  margin-bottom: 0.5em;

  /* Style */
  background: url("../../public/images/icon.png");
}

.background {
  /* Style */
  height: 100vh;
  background: url("https://i.imgur.com/0XuuWk8.png");
  background-size: cover;
}

.card {
  /* Position */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* Layout */
  width: 702px;
  height: 407px;
  flex-shrink: 0;
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 1.4s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flipped {
  transition: all ease 1.4s 0s;
  transform: rotateX(180deg) rotate(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.card-front {
  /* Style */
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.06);
  backdrop-filter: blur(10px);
}

.card-back {
  /* Style */
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.06);
  backdrop-filter: blur(10px);
  transform: rotateY(180deg);
}

.vertical-border {
  stroke-width: 1px;
  stroke: rgba(255, 255, 255, 0.12);
}

.social-buttons {
  /* Layout */
  margin-top: 1.3em;
  display: flex;
  flex-direction: row;
  gap: 5px;
  flex-shrink: 0;
}

.content-container {
  margin-top: 1em;
  margin-left: 2.3em;

  color: #e6e6e6;
  font-family: Titillium Web;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 137.1%; /* 21.936px */
}

.email-icon {
  fill: white;
  width: 1em;
}

.label-back-title {
  color: #e6e6e6;
  font-family: Titillium Web;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 137.1%; /* 49.356px */
  margin-bottom: 0.3em;
}

.label-back-content {
  color: #e6e6e6;
  font-family: Titillium Web;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 137.1%; /* 27.42px */
  margin-left: 1.7em;
}

.label-content {
  color: #e6e6e6;
  font-family: Titillium Web;
}

.label-back-icon {
  fill: white;
  width: 1.3em;
}
