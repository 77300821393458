@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;700&display=swap');
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/static/pretendard-dynamic-subset.css");

html {
  max-width: 100%;
  height: 100%;
  overflow-x: hidden;
  background-color: #121212;
  color: #e3e3e3;
}

body {
  max-width: 100%;
  height: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #313131; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #6f6f6f
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}